<template>
  <div class="organization">
    <div class="organization__wrapper">
      <h1 class="organization__title">Заявка № ТА{{id}}</h1>
      <small class="cursor-pointer text-teal-500" @click="$router.back()">Назад</small>


      <div class="organization__form-section">

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <div>
            <vue-dropzone ref="passport"
                          class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"
                          id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="setPassport"
                          @vdropzone-file-added-manually="setPassport"
                          @vdropzone-removed-file="removePassport"></vue-dropzone>
          </div>
          <div>
            <vue-dropzone ref="documents"
                          class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"
                          id="dropzone2" :options="dropzoneOptions2"
                          @vdropzone-file-added="setDocuments"
                          @vdropzone-file-added-manually="setDocuments"
                          @vdropzone-removed-file="removeDocuments"></vue-dropzone>
          </div>

<!--          <div>-->
<!--            <vue-dropzone ref="payments"-->
<!--                          class="dark:bg-gray-800 dark:hover:border-gray-700 hover:cursor-pointer h-full rounded"-->
<!--                          id="dropzone3" :options="dropzoneOptions3"-->
<!--                          @vdropzone-file-added="setPayments"-->
<!--                          @vdropzone-file-added-manually="setPayments"-->
<!--                          @vdropzone-removed-file="removePayments"></vue-dropzone>-->
<!--          </div>-->
        </div>


        <button
                class="mt-4 organization__action dark:bg-green-400 bg-green-400" @click="submit">
          <svg class="spinner float-left mr-2" viewBox="0 0 50 50" v-if="loading">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
          Обновить
        </button>
      </div>


    </div>

  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {validationMixin} from 'vuelidate'
import axios from 'axios'
import {mapGetters} from 'vuex'
import {normalizeFloat} from "@/helpers/functions";


export default {
  mixins: [validationMixin],
  components: {vueDropzone: vue2Dropzone},
  data: () => ({
    loading: false,
    id: null,
    declaration_id:null,
    ptoList: [
      {
        name: "06649 - ПТО \"Белтаможсервис-ТЛЦ\"",
        value: "06649"
      },
      {
        name: "06650 - ТЛЦ \"Минск-Белтаможсервис-2\" ",
        value: "06650"
      },
    ],
    brandsList: [],
    typesList: [],
    categoriesList: [],
    currenciesList: [],
    docTypes: [],
    licenceTypes: [
      {
        name: 'без льготы',
        value: 'без льготы',
      },
      {
        name: 'удостоверение инвалида',
        value: 'удостоверение инвалида',
      },
      {
        name: 'удостоверение многодетной семьи',
        value: 'удостоверение многодетной семьи',
      }
    ],
    engineTypes: [
      {
        name: 'бензин (в т.ч. гибрид)',
        value: 'бензин',
      },
      {
        name: 'дизель (в т.ч. гибрид)',
        value: 'дизель',
      },
      {
        name: 'электромобиль',
        value: 'электромобиль',
      }
    ],
    dropzoneOptions: {
      url: 'https://declarant.by/rest/user/transport-declaration/add',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 50000000,
      maxFiles: 3,
      uploadMultiple: true,
      addRemoveLinks: true,
      dictDefaultMessage: 'Копия паспорта',
      dictMaxFilesExceeded: 'Максимум 3 файла',
      dictRemoveFile: 'Удалить'
    },
    dropzoneOptions2: {
      url: 'https://declarant.by/rest/user/transport-declaration/add',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 50000000,
      addRemoveLinks: true,
      maxFiles: 10,
      uploadMultiple: true,
      dictDefaultMessage: 'Товаросопроводительные  документы (справка от дилера)',
      dictMaxFilesExceeded: 'Максимум 10 файлов',
      dictRemoveFile: 'Удалить'
    },
    // dropzoneOptions3: {
    //   url: 'https://declarant.by/rest/user/transport-declaration/add',
    //   autoProcessQueue: false,
    //   thumbnailWidth: 150,
    //   maxFilesize: 50000000,
    //   addRemoveLinks: true,
    //   maxFiles: 1,
    //   uploadMultiple: true,
    //   dictDefaultMessage: 'Копия электронного чека',
    //   dictMaxFilesExceeded: 'Максимум 1 файл',
    //   dictRemoveFile: 'Удалить'
    // },
    form: {
      uf_country: '',
      uf_brand: '',
      uf_model: '',
      uf_vin: '',
      uf_cost: '',
      uf_volume: '',
      uf_date: '',
      uf_pto: '06650',
      uf_dealer_doc: '',
      uf_passport_surname: '',
      uf_passport_name: '',
      uf_passport_middlename: '',
      uf_passport_doctype: '',
      uf_passport_number: '',
      uf_passport_date_on: '',
      uf_passport_date_off: '',
      uf_passport_person_id: '',
      uf_passport_index: '',
      uf_passport_region: '',
      uf_passport_city: '',
      uf_passport_street: '',
      uf_passport_house: '',
      uf_passport_room: '',
      uf_licence_type: '',
      uf_vehicle_category: '',
      uf_vehicle_engine_type: '',
      uf_vehicle_type: '',
      uf_cost_currency: '',
      uf_place_count: null,
      uf_weight: null,
      uf_offer_agreement: false,
      uf_contact_phone: ''
    },
    passport: '',
    documents: '',
    // payments: '',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      dark: 'darkTheme'
    }),
  },
  created() {
    this.id = this.$route.params.id
    this.declaration_id = this.$route.params.declaration_id
    this.fetchBrandsList()
    this.fetchTransportTypes()
    this.fetchTransportCategories()
    this.fetchCurrencies()
    this.fetchDocTypes()
  },
  methods: {
    fetchDocTypes() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiIdentityDocuments').then(res => {
        this.docTypes = res.data?.list?.filter((item) => item.code.startsWith('BY')) ?? []
      })
    },
    fetchCurrencies() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiCurrencies').then(res => {
        this.currenciesList = res.data?.list.map(item => {
          return {
            ...item,
            text: `${item.letterCode} (${item.name})`,
            search: [item.letterCode, item.name, item.digitCode, item.ownName].join('-').toLowerCase(),
          }

        }) ?? []
      })
    },
    fetchTransportTypes() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiVehicleTypes').then(res => {
        this.typesList = res.data?.list?.filter((item) => item.code.startsWith('3')) ?? []
      })
    },
    fetchTransportCategories() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiTransportCategories').then(res => {
        this.categoriesList = res.data?.list?.filter((item) => !item.code.startsWith('S')) ?? []
        this.categoriesList = this.categoriesList.map((item) => {
          return {
            ...item,
            text: `${item.code} - ${item.name}`
          }

        })
      })
    },
    fetchBrandsList() {
      axios.get('https://web.declarant.by/api/helper/api/read/nsiTransportMarks').then(res => {
        this.brandsList = res.data?.list?.map(item => {
          return {
            ...item,
            search: [item.name, item.own_name].join('-')
          }
        }) ?? []
      })
    },
    setPassport(file) {
      this.passport = file.name
    },
    removePassport() {
      this.passport = ''
    },
    setDocuments(file) {
      this.documents = file.name
    },
    removeDocuments() {
      this.documents = ''
    },
    setPayments(file) {
      this.payments = file.name
    },
    // removePayments() {
    //   this.payments = ''
    // },
    onEngineChange(v) {
      if (v === 'электромобиль' && this.form.uf_volume) {
        this.form.uf_volume = ''
      }
    },
    onCostChange(event) {
      this.form.uf_cost = normalizeFloat({value: event.target.value, afterPoint: 2})
    },
    submit() {
      if(!this.id || !this.declaration_id){
        return this.$notify({
          title: 'Ошибка',
          text: 'Отсутствуют обязательные параметры',
          type: 'error'
        })
      }
      let data = new FormData()
      let passport = this.$refs.passport.getQueuedFiles()
      let documents = this.$refs.documents.getQueuedFiles()
      // let payments =  this.$refs.payments.getQueuedFiles()

      passport.forEach((file, index) => {
        data.append(`passport-${index}`, file)
      })
      documents.forEach((file, index) => {
        data.append(`documents-${index}`, file)
      })

      // payments.forEach((file, index) => {
      //   data.append(`payment-${index}`, file)
      // })

      data.append('declaration_id', this.declaration_id)

      axios({
        method: 'post',
        url: `https://declarant.by/rest/user/transport-declaration/edit/${this.id}`,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }).then(res => {
        this.loading = false
        this.$notify({
          title: 'Заявка сохранена',
          text: res.data.data,
          type: 'success'
        })
        this.$router.push({name:'TransportDeclaration'})
      }).catch(err => {
        this.loading = false
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    },

  },

}
</script>

<style scoped>
.tooltip {
  display: none !important;
}

i:hover + .tooltip {
  display: block;
}

.checkbox input {
  opacity: 0;
  position: absolute;
}

.checkbox input + label {
  display: inline-block;
  padding: 2px;
  margin: 2px 8px -2px 0;
  cursor: pointer;
  line-height: 22px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.checkbox input + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 6px;
  border-radius: 6px;
  height: 20px;
  width: 20px;
  border: 2px solid gray;

}

.checkbox input:checked + label {
  color: rgb(52, 211, 153);
}

.checkbox input:checked + label:before {
  background: rgb(52, 211, 153);
  border-color: rgb(52, 211, 153);
}

label {
  display: block;
  margin: 0 0 10px 0;
}

select {
  margin: 0 !important;
}


.organization {
  @apply my-10  mx-auto relative
  xl:container xl:px-5;
}

.organization__wrapper {
  @apply relative;
}

.organization__title {
  @apply text-3xl
}

.organization__nav {
  @apply flex justify-center py-5 w-full
  sm:flex-col
  xl:flex-row
}

.organization__nav-item {
  @apply border dark:border-gray-700 border-gray-300  w-full
  sm:p-2
  xl:p-7

}

.organization__nav-item-text {
  @apply text-2xl font-semibold
}

.organization__form-section {
  @apply py-5
}

.organization__form-section select {
  min-height: 44px !important;
}

.organization__form-files {
  @apply flex items-end my-5
  sm:flex-col
  xl:flex-row
}

.organization__file-input-wrapper {
  @apply sm:w-full
  xl:w-1/2 xl:mx-1
}

.organization__file-input-title {
  @apply text-xl mb-2
}

.organization__check-wrapper {
  @apply flex mb-1
  sm:flex-col
  xl:flex-row
}

.organization__check-group {
  @apply dark:bg-gray-800 bg-blue-100 flex items-center justify-center text-center
  sm:w-full sm:py-2
  xl:w-1/4 xl:py-0
}

input[type=text], select {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  rounded bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white;
}

.organization__dropzone {
  @apply dark:bg-gray-800 rounded bg-gray-300 dark:hover:border-gray-800 dark:border-gray-700
  sm:mb-10
  xl:mb-0
}

.organization__check-result {
  @apply
  sm:w-full
  xl:w-3/4
}

.organization__check-text {
  @apply px-3 py-1
}

.organization__rule {
  @apply px-5
}

.organization__actions-wrapper {
  @apply flex justify-center
}

.organization__action {
  @apply px-5 py-3 rounded
}

.file-loader {
  @apply absolute w-1/4 top-0 right-0 flex items-center rounded p-5 bg-blue-300 opacity-90 z-30;
}

select::-webkit-scrollbar {
  display: none;
}

.group-select:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}

.group-select:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

* >>> .dz-progress {
  display: none !important;
  opacity: 0 !important;
}

.datepicker {
  width: 100% !important;
}

input, .datepicker {
  max-height: 44px !important;
  margin: 0 !important;
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent rounded
  bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white
}

input[type="radio"] {
  width: inherit;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  border: 1px solid #2196F3;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 20px;
  height: 20px;
  position: relative;
}

.spinner .path {
  stroke: rgb(255, 255, 255);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


</style>